export class CLIENTS_ROUTES {
	static ROOT = '/clients';
	static ROOT_1 = 'clients';
	static ACC_DOCUMENTS = 'accdocuments';
	static OPIS_DOCUMENTS = 'opis';
	static EMPLOYEES = 'employees';
	static TIMESHEET = 'timesheet';
	static OPERATIONAL_REPORT = 'operationalreport';
	static ACCOUNTING_HOME = 'accounting';
	static PAYROLL_HOME = 'payroll';
	static PAYROLL_DOCUMENTS = 'payrolldocuments';
	static CLIENTPARTNERS = 'partners';
	static SUBSCRIPTION = 'subscription';
	static COMPANY = 'company';
	static COMPANY_BANK_ACCOUNTS = 'bankaccounts';
	static COMPANY_EMPLOYEES_CARDS = 'employeescards';
	static MISC = 'misc';
	static TEMPLATES = 'templates';
	static VEHICLES = 'vehicles';
	static USERS = 'users';
	static AGENTS = 'agents';
	static NOTIFICATIONS = 'notifications';
	static CHAT = 'chat';
	static BALANCE_SHEET = 'balance';
	static BALANCE_SHEET_CLIENT = 'balance-sheet-client';
	static INVOICES = 'invoices';
	static ITEMS = 'items';
	static CLARIFICATION = 'clarification';
	static CARD_CLARIFICATION = 'card-clarification';
	static IMPORT = 'import';
	static ACCOUNTING_REPORTS = 'accountingReports';
	static MISSING_DOCUMENTS = 'missingDocuments';
	static CLIENT_CONTRACT = 'clientcontract';
	static E_FACTURA = 'e-factura';
	static DOCUMENT_PASSWORDS = 'document-passwords';
	static TAKEOVER = 'takeover';
	static INVOICE_SERIES = 'invoice-series'
	static PARAMETERS = 'parameters';
}
